<template>
  <div>
    <div class="position-relative py-3 px-4 text-center">
      <div :class="$style.flag">$560,245.35</div>
      <div class="font-size-70 pt-3 pb-w text-gray-4">
        <i class="fe fe-star" />
      </div>
      <h5 class="font-size-24 font-weight-bold mb-1">David Beckham</h5>
      <div class="font-size-18 text-uppercase mb-3">8748-XXXX-1678-5416</div>
      <div class="font-weight-bold font-size-18 text-uppercase mb-4">MASTERCARD</div>
      <div class="border-top pt-3 font-italic">Expires at 03/22</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitGeneral17',
}
</script>
<style lang="scss" module>
  @import './style.module.scss';
</style>
